<template>
  <div
    class="w-full my-1 text-sm text-gray-600 p-2 rounded-sm cursor-pointer"
    :class="editingNote ? 'bg-blue-50' : 'hover:bg-gray-50 group'"
  >
    <loader :is-loading="loading" />
    <div :class="{ flex: !isApplicationNote }">
      <div
        v-if="!isApplicationNote"
        class="rounded-full bg-disabled-medium flex justify-center items-center w-8 h-8 mr-3"
      >
        <icon-base
          v-if="!isApplicationNote"
          :icon="IconDealNotes"
          height="17"
          width="17"
          class="text-white ml-1 -mt-0-25"
        />
      </div>
      <div class="w-full note-content">
        <div
          class="space-y-2"
          :class="{
            'justify-between flex flex-row-reverse': !isApplicationNote
          }"
        >
          <div
            class="flex items-center w-full space-x-2"
            :class="isApplicationNote ? 'justify-between' : 'justify-end'"
          >
            <span
              v-if="showStatusDescription"
              v-tooltip="{
                content: statusDescription,
                placement: 'left',
                onShow: () => statusDescription.length < 30
              }"
              class="whitespace-nowrap max-w-3/4 text-ellipsis overflow-hidden rounded-full text-xxs uppercase py-1 px-2-5"
              :style="getPillStyle({ color: COLORS[colorCode] })"
            >
              {{ statusDescription }}
            </span>
            <div
              class="invisible group-hover:visible flex items-center justify-end w-1/4"
            >
              <icon-base
                title="copyBtn"
                icon="copy-link-no-bg"
                height="18"
                width="18"
                class="text-gray-300 hover:text-gray-500 mr-2"
                @click="copyNoteLink(adaptedNote.id)"
              />
              <icon-base
                v-if="isCurrentUserNote"
                :title="adaptedNote.id"
                height="20"
                width="20"
                icon="edit"
                class="text-gray-300 hover:text-gray-500 mr-2"
                @click="editNote"
              />
              <icon-base
                v-if="isCurrentUserNote || isSuperAdmin"
                height="20"
                width="20"
                icon="trashcan-outline"
                class="text-gray-300 hover:text-gray-500"
                @click="deleteNote"
              />
            </div>
          </div>
          <div class="note-body w-full break-long-words text-gray-700">
            {{ adaptedNote.comment }}
          </div>
        </div>
        <div
          class="note-footer text-xs flex text-gray-400"
          :class="isApplicationNote ? 'mt-5' : 'mt-1'"
        >
          <span class="user-name">
            {{ formatUserFullName(adaptedNote.user) }}
          </span>
          <span class="px-1 separator">•</span>
          <span class="updated-date">
            {{ formatDate(adaptedNote.updated_at) }}
          </span>
          <div v-if="isApplicationNote" class="flex items-center space-x-2">
            <seen-note-button
              :seen-by="note.read_notes || []"
              class="ml-0.5 -mt-1"
              data-testid="seen-notes-button"
            />
            <icon-base
              v-tooltip="tooltipText"
              height="12"
              width="12"
              view-box="0 0 16 16"
              :icon="buttonIcon"
              icon-color="gray"
              class="-mt-2"
              :data-testid="buttonIcon"
            />
          </div>

          <icon-base
            v-if="!isApplicationNote && showVisibilityIcon"
            height="20"
            width="20"
            view-box="0 0 20 14"
            icon="show-pass-no-bg"
            class="ml-1 text-gray-500"
            data-testid="visibility-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  NOTE_VISIBILITY_SCOPE,
  ALL_NOTE_VISIBILITY_TYPES,
  NOTE_VISIBILITY_SCOPES,
  NOTES_TYPES,
  STATUS_COLOR_CODES,
  COLORS
} from "@/helpers/constants";
import { useStore } from "vuex";
import { ref, computed, toRef } from "vue";
import { useRoute } from "vue-router";
import useApplicationsStore from "@/stores/applications";
import { useClipboardWithMessage } from "@/hooks/clipboard";
import { useAuth } from "@/hooks/auth";
import { useDealsBase } from "@/hooks/deals";
import { WORKFLOW_TO_APP_STATUS } from "@/helpers/constants/workflow";
import { getPillStyle } from "@/helpers/UI";

import type { PropType } from "vue";
import type {
  ApplicationStatus,
  IApplicationNote
} from "@/models/applications";
import type { IFlowRecord, IWorkflowStage } from "@/models/workflows";
import type { Note, primaryVisibilityScope } from "@/models/common";
</script>

<script setup lang="ts">
import { formatUserFullName, formatDate } from "@/helpers/formatting";
import { storeToRefs } from "pinia";
import SeenNoteButton from "@/views/deals/components/dealNotes/SeenNoteButton.vue";
import IconDealNotes from "@/components/icons/dealNotes/DealNotes.vue";
import IconPeople from "@/components/icons/IconPeople.vue";
import IconUser from "@/components/icons/IconUser.vue";
import IconStar from "@/components/icons/IconStar.vue";
import type { Component } from "vue";

const iconMap: Record<string, Record<string, Component>> = {
  people: IconPeople,
  user: IconUser,
  star: IconStar
};

const props = defineProps({
  note: {
    type: Object as PropType<IApplicationNote>,
    required: true
  },
  editingNote: {
    type: Boolean,
    default: false
  },
  noteType: {
    type: String as PropType<Note>,
    default: NOTES_TYPES.APPLICATION_NOTE
  }
});

const emit = defineEmits<{
  (eventName: "setEditNote", note: IApplicationNote): void;
}>();

const { dispatch, getters } = useStore();
const { isSuperAdmin } = useAuth();
const route = useRoute();
const applicationsStore = useApplicationsStore();
const copyToClipboard = useClipboardWithMessage();
const { activeDeal } = useDealsBase();

const loading = ref(false);
const adaptedNote = toRef(props, "note");
const { canSeeNotes } = storeToRefs(applicationsStore);

const VISIBILITY_SCOPES_WITH_BUTTONS = [1, 3];
const OTHER_VISIBILITY_SCOPE_INDX = 99;

const DELETE_NOTE_ACTIONS = {
  [NOTES_TYPES.APPLICATION_NOTE]: "deleteApplicationNote",
  [NOTES_TYPES.BUSINESS_NOTE]: "applications/deleteBusinessNote"
};

const flows = computed<IFlowRecord[]>(
  () => getters["workflows/activeTemplateFlow"]
);

const stages = computed<IWorkflowStage[]>(
  () => getters["workflows/activeTemplateStages"]
);

const isApplicationNote = computed(
  () => props.noteType === NOTES_TYPES.APPLICATION_NOTE
);

const isCurrentUserNote = computed(
  () => getters["auth/user"]?.id === adaptedNote.value.user?.id
);

const noteFlow = computed(() =>
  flows.value.find((flow) => flow.id === props.note.stage)
);
const noteStage = computed(() =>
  stages.value.find((stage) => stage.id === noteFlow.value?.stage_id)
);

const colorCode = computed(() => {
  const stageType = noteStage.value?.type;
  if (stageType === undefined) {
    return 0;
  }
  const statusCode =
    WORKFLOW_TO_APP_STATUS[stageType as keyof typeof WORKFLOW_TO_APP_STATUS];
  return STATUS_COLOR_CODES[statusCode as ApplicationStatus];
});

const statusDescription = computed(() => noteFlow.value?.name || "");

const showVisibilityIcon = computed(
  () =>
    canSeeNotes.value &&
    adaptedNote.value?.visibility_scope !== NOTE_VISIBILITY_SCOPE.ALL
);

const visibilityIconIndx = computed<primaryVisibilityScope>(() => {
  if (VISIBILITY_SCOPES_WITH_BUTTONS.includes(props.note.visibility_scope)) {
    return props.note.visibility_scope as primaryVisibilityScope;
  }
  return OTHER_VISIBILITY_SCOPE_INDX;
});

const buttonIcon = computed(
  () => iconMap[NOTE_VISIBILITY_SCOPES[visibilityIconIndx.value].icon]
);

const tooltipText = computed(
  () => `visibility: ${ALL_NOTE_VISIBILITY_TYPES[props.note.visibility_scope]}`
);

const showStatusDescription = computed(
  () => isApplicationNote.value && !!statusDescription.value
);

const deleteNote = async () => {
  const payload =
    props.noteType === NOTES_TYPES.APPLICATION_NOTE
      ? { noteId: props.note.id, applicationId: activeDeal.value.id }
      : props.note.id;
  loading.value = true;
  if (props.noteType === NOTES_TYPES.BUSINESS_NOTE) {
    await dispatch(DELETE_NOTE_ACTIONS[props.noteType], payload);
  } else if (typeof payload !== "number") {
    await applicationsStore.deleteApplicationNote(payload);
  }
  loading.value = false;
};

const copyNoteLink = (id: number) => {
  const origin = location?.origin || "";
  if (isApplicationNote.value) {
    copyToClipboard(`${origin}${route.path}?note=${id}`);
    return;
  }
  copyToClipboard(`${origin}${route.path}?businessNote=note-${id}`);
};

const editNote = async () => {
  emit("setEditNote", props.note);
};
</script>

<style scoped>
.break-long-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  white-space: pre-wrap;
}

.note-content {
  container-type: inline-size;
}

@container (max-width: 300px) {
  .note-footer {
    @apply flex-col space-y-1;
  }
  .note-footer .separator {
    display: none;
  }
}
</style>
